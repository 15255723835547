import { CmsElements, CmsElementsJsonData } from "@/types/Facet";

export function useFetcCmsElements() {
  const { $fireBangApiDomain } = useNuxtApp();
  // 保険取扱代理店名取得
  const { data: fetchInsuranceAgentList } = useFetch(
      `${$fireBangApiDomain}/api/cms/elements`,
    {key:'useFetchData',
      transform: (data: CmsElementsJsonData) => {
        const targetElement = data.elements.find(
          (el: CmsElements) => el.key === 'insurance_agent'
        );
        const targetElementTrim = targetElement
          ? targetElement.value.split("\r\n").map((item: string) => item.trim())
          : [];
        return targetElementTrim;
      },
    }
  );
    return {
    fetchInsuranceAgentList,
  };
}
